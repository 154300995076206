import React from "react";
import PropTypes from "prop-types";
import CapsLabel from "./";

const PublicationCapsLabel = ({ text }) =>
  text && <CapsLabel className="e-caps-label--publication" text={text} />;

export default PublicationCapsLabel;

export const PublicationCapsLabelPropTypes = {
  text: PropTypes.string,
};

PublicationCapsLabel.propTypes = PublicationCapsLabelPropTypes;
