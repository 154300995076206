import React, { useContext } from "react";
import PropTypes from "prop-types";
import ImageHelper from "../helpers/image";
import { VideoModalContext } from "../context/videoModalContext";

const VideoWithOverlay = ({
  image,
  alt = "",
  videoUrl,
  overlayColor = "", //TODO dynamically set colour
  overlayText = "",
}) => {
  const { setVideoUrl } = useContext(VideoModalContext);
  return (
    <div
      className="e-landscape-image-container e-image-container"
      style={{ position: "relative", cursor: "pointer" }}
      onClick={() => setVideoUrl(videoUrl)}
      id="watch-video"
    >
      <ImageHelper image={image} alt={alt} />
      <div className="e-image-overlay">
        <div className="e-image-overlay-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4526 0.873779C6.10486 0.873779 0.957886 6.02075 0.957886 12.3685C0.957886 18.7163 6.10486 23.8633 12.4526 23.8633C18.8004 23.8633 23.9474 18.7163 23.9474 12.3685C23.9474 6.02075 18.8004 0.873779 12.4526 0.873779ZM16.1499 12.5456L10.5462 16.6226C10.5155 16.6447 10.4793 16.6579 10.4416 16.6607C10.4039 16.6635 10.3661 16.6559 10.3324 16.6387C10.2987 16.6214 10.2705 16.5952 10.2507 16.563C10.2309 16.5307 10.2204 16.4936 10.2204 16.4558V8.30687C10.2203 8.26897 10.2306 8.23177 10.2504 8.1994C10.2701 8.16702 10.2984 8.14075 10.3321 8.12348C10.3659 8.10621 10.4037 8.09862 10.4415 8.10155C10.4793 8.10448 10.5156 8.11783 10.5462 8.1401L16.1499 12.2146C16.1764 12.2333 16.198 12.2581 16.2129 12.2869C16.2278 12.3157 16.2356 12.3476 16.2356 12.3801C16.2356 12.4125 16.2278 12.4444 16.2129 12.4732C16.198 12.502 16.1764 12.5268 16.1499 12.5456Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="e-image-overlay-text">{overlayText}</div>
      </div>
    </div>
  );
};

export default VideoWithOverlay;

export const VideoWithOverlayPropTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  videoUrl: PropTypes.string,
  overlayColor: PropTypes.string,
  overlayText: PropTypes.string,
};

VideoWithOverlay.propTypes = VideoWithOverlayPropTypes;
