import React from "react";
import CapsLabel from "./";
import PropTypes from "prop-types";

const TopicCapsLabel = ({ text }) =>
  text && <CapsLabel className="e-caps-label--topic" text={text} />;

export default TopicCapsLabel;

export const TopicCapsLabelPropTypes = {
  text: PropTypes.string,
};

TopicCapsLabel.propTypes = TopicCapsLabelPropTypes;
