import React from "react";
import PropTypes from "prop-types";
import PublicationCapsLabel from "./caps-label/publication";

const CardTitle = ({ text, publication }) =>
  text && (
    <div className="e-card-title">
      <h3 className="h5">{text}</h3>
      {publication && <PublicationCapsLabel text={publication} />}
    </div>
  );

export default CardTitle;

export const CardTitlePropTypes = {
  text: PropTypes.string,
  publication: PropTypes.string,
};

CardTitle.propTypes = CardTitlePropTypes;
