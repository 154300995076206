import React from "react";
import PropTypes from "prop-types";

//TODO: Move CSS to main file
const SmallDateLabel = ({ date }) =>
  date ? <div className="body-xs e-small-date-label">{date}</div> : null;

export default SmallDateLabel;

export const SmallDateLabelPropTypes = {
  date: PropTypes.string,
};

SmallDateLabel.propTypes = SmallDateLabelPropTypes;
