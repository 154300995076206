import React from "react";
import PropTypes from "prop-types";
import VideoWithOverlay from "../elements/video-with-overlay";
import SmallDateLabel from "../elements/small-date-label";
import VideoDescription from "../elements/video-description";

const VideoCard = ({ videoUrl, title, text, date, image, tags }) => {
  return (
    <div className="c-content-block">
      {image && videoUrl ? (
        <VideoWithOverlay
          image={image}
          overlayText={title}
          videoUrl={videoUrl}
        />
      ) : null}
      {date ? <SmallDateLabel date={date} /> : null}
      {title ? (
        <div className="e-card-title" style={{ marginBottom: 0 }}>
          <h3 className="h5" style={{ marginBottom: 0 }}>
            {title}
          </h3>
        </div>
      ) : null}
      {text ? <VideoDescription description={text} /> : null}
      {tags ? <SmallDateLabel date={tags} /> : null}
    </div>
  );
};

export default VideoCard;

export const VideoCardPropTypes = {
  videoUrl: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  tags: PropTypes.string,
};

VideoCard.propTypes = VideoCardPropTypes;
