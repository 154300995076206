import React from "react";
import MediaContact, {
  MediaContactPropTypes,
} from "../components/media-contact";
import BasicImage, { BasicImagePropTypes } from "../elements/basic-image";
import PropTypes from "prop-types";
import DatesCapsLabel from "../elements/caps-label/dates";
import { HeaderBG, Home } from "../helpers/icons";
import headerBackground from "../helpers/header-background";
import HeroSidebar from "../components/hero-sidebar";
import { Link } from "gatsby";

const Header = ({
  title,
  text,
  image,
  breadcrumbs,
  size = "medium",
  date,
  dateEnd,
  contact,
  backgroundColour = "orange",
  sidebar,
}) =>
  title && (
    <div
      className="m-header"
      style={{
        "--background": headerBackground[backgroundColour],
        "--text":
          backgroundColour === "green" || backgroundColour === "blue"
            ? "#fff"
            : "#222",
      }}
    >
      <div className="m-header-inner">
        <div className="m-header-main">
          {breadcrumbs && breadcrumbs.length && (
            <Breadcrumbs items={breadcrumbs} />
          )}
          <div className="m-header-body">
            {date && <DatesCapsLabel start={date} end={dateEnd} />}
            <h1 className="m-header-title h2">{title}</h1>
            {text && <div className="m-header-text body-regular">{text}</div>}
          </div>
          <HeaderBG className="m-header-bg" />
        </div>
        <div className="m-header-aside">
          {image && size === "medium" && !contact && (
            <div className="m-header-image">
              <div className="e-image-container e-landscape-image-container">
                <BasicImage image={image} />
              </div>
            </div>
          )}
          {image && size === "large" && !contact && (
            <div className="m-header-image">
              <div className="e-image-container e-square-image-container">
                <BasicImage image={image} />
              </div>
            </div>
          )}
          {contact && !image && (
            <div className="m-header-contact">
              <MediaContact {...contact} />
            </div>
          )}
          {sidebar && !image && <HeroSidebar content={sidebar} />}
        </div>
      </div>
      <div className="m-header-clip"></div>
    </div>
  );

const Breadcrumbs = ({ items }) => {
  let path = "";
  return (
    <div className="m-header-breadcrumbs">
      <Link className="m-header-breadcrumb" to="/">
        <span>Home</span>
        <Home className="m-header-home-icon" />
      </Link>
      {items.map((breadcrumb, i) => {
        path += `/${breadcrumb.slug}`;
        return (
          breadcrumb.title &&
          breadcrumb.slug && (
            <Link className="m-header-breadcrumb" to={path} key={i}>
              <span>{breadcrumb.title}</span>
            </Link>
          )
        );
      })}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  image: PropTypes.shape(BasicImagePropTypes),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, slug: PropTypes.string })
  ),
  size: PropTypes.oneOf(["large", "medium"]),
  date: PropTypes.instanceOf(Date),
  dateEnd: PropTypes.instanceOf(Date),
  contact: PropTypes.shape(MediaContactPropTypes),
  backgroundColour: PropTypes.string,
  textColour: PropTypes.string,
  sidebar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, slug: PropTypes.string })
  ),
};

export default Header;
