import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import Pagination from "../components/Pagination";
import Layout from "../layouts";
import ContentBlockList from "../modules/content-block-list";
import Header from "../modules/header";

export const query = graphql`
query BlogPagesQuery($limit: Int, $skip: Int) {
  articles: allSanityArticle(
    skip: $skip
    limit: $limit
    sort: {fields: publishDate, order: DESC}
    filter: {
      slug: { current: { ne: null } }
      articleType: {eq: "Blog"}
    }
  ) {
    totalCount
    nodes {
      title
      heroTitle
      path
      excerpt
      authorText
      publishDate(formatString: "ddd MMMM D, YYYY")
      slug {
        current
      }
      image {
        asset {
          url
        }
      }
    }
  }
}
`

const BlogPage = (props) => {
  const { page, numPages, limit, skip } = props.pageContext;
  const data = props.data

  const refine = (nextPage) => {
    window.location.href = "/news/graingrowers-blog/" + nextPage
  }

  const pageItems = data.articles.nodes.map(node=>({
    title: node.authorText,
    subtitle: node.title,
    text: node.excerpt,
    image: node.image?.asset?.url,
    date: node.publishDate,
    link:{
      page:{
        slug:{
          current: node.slug
        },
        title:node.title,
        path:node.path,
      },
      type: "internal",
    },
  }))

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header
        title="GrainGrowers Blog"
        slug="graingrowers-blog"
        backgroundColour="beige"
        breadcrumbs={[
          { title: "News", slug: "news" },
          { title: "GrainGrowers Blog", slug: "graingrowers-blog" },
        ]}
      />
      <div className="container">
          <ContentBlockList content={pageItems} usePlaceholderImages={true}/>
          { numPages > 1 &&
            <Pagination currentRefinement={page} nbPages={numPages} refine={refine}/>
          }
        </div>
    </Layout>
  );
};

export default BlogPage;