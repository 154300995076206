import React from "react";
import PropTypes from "prop-types";
import Obfuscate from "react-obfuscate";
import EmailLink from "../elements/email-link";

const MediaContact = ({ title = "Media contact", name, role, phone, email }) =>
  name && (
    <div className="c-media-contact">
      <div className="c-media-contact-inner">
        <h3 className="c-media-contact-title subtitle">{title}</h3>
        <p className="c-media-contact-name body-small bold">{name}</p>
        {role && <p className="c-media-contact-role body-small">{role}</p>}
        {phone && (
          <p className="c-media-contact-phone body-small">
            Phone: <Obfuscate tel={phone} className="body-small" />
          </p>
        )}
        {email && <EmailLink email={email} className="body-small" />}
      </div>
    </div>
  );

export default MediaContact;

export const MediaContactPropTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
};

MediaContact.propTypes = MediaContactPropTypes;
