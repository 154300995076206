import React from "react";
import PropTypes from "prop-types";

const VideoDescription = ({ description }) =>
  description ? (
    <div className="body-regular e-video-description">{description}</div>
  ) : null;

export default VideoDescription;

export const VideoDescriptionPropTypes = {
  description: PropTypes.string,
};

VideoDescription.propTypes = VideoDescriptionPropTypes;
