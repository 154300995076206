import React from "react";
import PropTypes from "prop-types";
import TopicCapsLabel from "../elements/caps-label/topic";
import CardTitle from "../elements/card-title";
import CardDate from "../elements/card-date";
import Button from "../elements/button";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helpers/text-serializer";
import linkHelper from "../helpers/link";

const ContentBlock = ({
  title,
  subtitle,
  text,
  linkText = "Find out more",
  link,
  date,
}) => {
  return link || title || text || subtitle || date ? (
    <div className="c-content-block">
      {title && <TopicCapsLabel text={title} />}
      {subtitle && <CardTitle text={subtitle} />}
      {date && <CardDate text={date} />}
      {text && (
        <div className="body-small e-content-block-text">
          {Array.isArray(text) && (
            <BlockContent
              blocks={text}
              serializers={{
                serializers,
                types: {
                  block: serializers.types.block,
                  undefined: serializers.undefined,
                  blockVideo: serializers.blockVideo,
                  blockFigure: serializers.blockFigure,
                  pdfButton: serializers.pdfButton,
                },
                marks: {
                  link: serializers.marks.link,
                },
              }}
            />
          )}
          {!Array.isArray(text) && <p>{text}</p>}
        </div>
      )}
      {link && (
        <Button
          {...linkHelper(link)}
          text={linkText}
          type="secondary"
          includeIcon={true}
        />
      )}
    </div>
  ) : (
    ""
  );
};

export default ContentBlock;

export const ContentBlockPropTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  linkText: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  usePlaceholderImage: PropTypes.bool,
};

ContentBlock.propTypes = ContentBlockPropTypes;
