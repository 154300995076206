import React, { useState, useEffect } from "react";

const Pagination = ({ currentRefinement, nbPages, refine }) => {
  const currentPage = currentRefinement;
  return (
    <ul className="m-search-pagination">
      {/* Left navigation arrow */}
      <li
        className={`m-search-paginate-button${currentPage === 1 ? " -disabled" : ""
          }`}
      >
        <a
          className="m-search-paginate-link"
          href="#search-list"
          onClick={e => {
            refine(1);
          }}
        >
          &lt;&lt;
        </a>
      </li>
      <li
        className={`m-search-paginate-button${currentPage === 1 ? " -disabled" : ""
          }`}
      >
        <a
          className="m-search-paginate-link"
          href="#"
          onClick={e => {
            refine(currentPage - 1);
          }}
        >
          &lt;
        </a>
      </li>
      {new Array(nbPages).fill(null).map((_, index) => {
        const page = index + 1;
        return (
          page >= currentRefinement - 2 &&
          page <= currentRefinement + 2 && (
            <li
              key={index}
              className={`m-search-paginate-button${currentRefinement === page ? " -active" : ""
                }`}
            >
              <a
                className="m-search-paginate-link"
                href="#search-list"
                onClick={e => {
                  refine(page);
                }}
              >
                {page}
              </a>
            </li>
          )
        );
      })}

      {/*  Right Navigation arrow */}
      <li
        className={`m-search-paginate-button${currentPage === nbPages ? " -disabled" : ""
          }`}
      >
        <a
          className="m-search-paginate-link"
          href="#search-list"
          onClick={e => {
            refine(currentPage + 1);
          }}
        >
          &gt;
        </a>
      </li>
      <li
        className={`m-search-paginate-button${currentPage === nbPages ? " -disabled" : ""
          }`}
      >
        <a
          className="m-search-paginate-link"
          href="#search-list"
          onClick={e => {
            refine(nbPages);
          }}
        >
          &gt;&gt;
        </a>
      </li>
    </ul>
  );
};

export default Pagination;