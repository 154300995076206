import React from "react";
import PropTypes from "prop-types";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helpers/text-serializer";

const HeroSidebar = ({ content }) =>
  content?.[0] && (
    <div className="c-media-contact">
      <BlockContent
        className="c-media-contact-inner"
        blocks={content}
        serializers={{
          serializers,
          types: {
            block: serializers.types.block,
            undefined: serializers.undefined,
            blockVideo: serializers.blockVideo,
            blockFigure: serializers.blockFigure,
            pdfButton: serializers.pdfButton,
          },
          marks: {
            link: serializers.marks.link,
          },
        }}
        renderContainerOnSingleChild={true}
      />
    </div>
  );

export default HeroSidebar;

export const HeroSidebarPropTypes = {
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

HeroSidebar.propTypes = HeroSidebarPropTypes;
