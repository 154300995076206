const headerBackground = {
  green: "#379144",
  gold: "#e8d781",
  teal: "#3cbfae",
  orange: "#fdbe57",
  blue: "#00599a",
  beige: "#f6f3ea",
};

export default headerBackground;
