import React from "react";
import PropTypes from "prop-types";
import ContentBlock, {
  ContentBlockPropTypes,
} from "../components/content-block";
import LandscapeImage, {
  LandscapeImagePropTypes,
} from "../elements/landscape-image";

import VideoCard, { VideoCardPropTypes } from "../components/video-tile";

const ContentBlockList = ({ content, title, usePlaceholderImages }) => {
  return (
    content?.[0] && (
      <div className="m-content-block-list">
        {title && (
          <div className="container">
            <h2 className="m-content-block-list-title h4">{title}</h2>
          </div>
        )}
        <div className="m-content-block-list-inner inner">
          {content.map((item, i) => {
            return (
              <div className="m-content-block-list-item" key={i}>
                {item.isVideo ? (
                  <div className="m-content-block-list-image">
                    <VideoCard {...item} text={item._rawText || item.text} />
                  </div>
                ) : (
                  <>
                    <>
                      {item.image ? (
                        <div className="m-content-block-list-image">
                          <LandscapeImage image={item.image} />
                        </div>
                      ) : null}
                    </>
                    <>
                      {!item.image && usePlaceholderImages ? (
                        <div className="m-content-block-list-image">
                          <LandscapeImage
                            image={
                              require("../img/placeholder/blog-image-placeholder.png")
                                .default
                            }
                          />
                        </div>
                      ) : null}
                    </>
                    <ContentBlock
                      {...item}
                      text={item._rawText || item.text}
                      link={item.link}
                      linkText={item.link?.buttonText || undefined}
                      usePlaceholderImage={usePlaceholderImages}
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

ContentBlockList.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape(
      ContentBlockPropTypes,
      LandscapeImagePropTypes,
      VideoCardPropTypes
    )
  ),
  title: PropTypes.string,
  usePlaceholderImages: PropTypes.bool,
};

export default ContentBlockList;
