import React from "react";
import PropTypes from "prop-types";

const CardDate = ({ text }) =>
  text && (
    <div className="e-card-date">{text}</div>
  );

export default CardDate;

export const CardDatePropTypes = {
  text: PropTypes.string,
};

CardDate.propTypes = CardDatePropTypes;
